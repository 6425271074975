<template>
  <div
    id="edit-business-info-page"
    class="edit-business-info-page"
  >
    <van-sticky>
      <div class="star-info">
        <img
          class="avatar"
          :src="detail && detail.avatar ? detail.avatar:require('@/assets/images/icon-avatar@2x.png')"
        >
        <div class="common-info">
          <div>
            <span class="nickname">{{ detail && detail.nickname ? detail.nickname : '-' }}</span>
            <img
              class="icon-gender"
              src="@/assets/images/icon-male@2x.png"
              v-if="detail && detail.gender === 1"
            >
            <img
              class="icon-gender"
              src="@/assets/images/icon-female@2x.png"
              v-else-if="detail && detail.gender === 2"
            >
          </div>
          <div class="brief">
            <span>ID: {{ detail ? (detail.short_id ? detail.short_id : detail.id) : '-' }}</span>
            <span class="mcn">MCN：
              <label class="mcn-name">
                {{
                  detail && detail.mcn && detail.mcn.name ? detail.mcn.name : '-'
                }}
              </label>
            </span>
          </div>
        </div>
      </div>
    </van-sticky>

    <section-text text="编辑达人权益" />

    <div class="edit-business-form">
      <div class="field">
        <div class="title">
          返点比例
        </div>
        <div class="right">
          <input
            class="input mini"
            placeholder="0-100"
            v-model="form.commissionRate"
          ><span class="ext">%</span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          视频保留时长
        </div>
        <div class="right">
          <input
            class="input"
            placeholder="免费3个月"
            v-model="form.videoKeepDuration"
          >
        </div>
      </div>
      <div class="field">
        <div class="title">
          视频授权及费用
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('authorizeVideo', actions_1, '视频授权及费用')"
          >
            <span>{{ getActionText(actions_1, form.authorizeVideo) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
          <input
            v-if="form.authorizeVideo"
            class="input second mini"
            placeholder="请输入价格"
            v-model="form.authorizeVideoFee"
          >
          <span
            class="ext"
            v-if="form.authorizeVideo"
          >
            元
          </span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          二次剪辑及费用
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('supportSecondaryCreation', actions_2, '二次剪辑及费用')"
          >
            <span>{{ getActionText(actions_2, form.supportSecondaryCreation) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
          <input
            v-if="form.supportSecondaryCreation"
            class="input second mini"
            placeholder="请输入价格"
            v-model="form.secondaryCreationFee"
          >
          <span
            class="ext"
            v-if="form.supportSecondaryCreation"
          >
            元
          </span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          授权投放品牌<br>DOU+
        </div>
        <div class="right">
          <input
            class="input"
            placeholder="免费授权本账号，内容热推1个月"
            v-model="form.authorizedMarketingBrand"
          >
        </div>
      </div>
      <div class="field">
        <div class="title">
          配合评论置顶
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('supportPinComment', actions_2, '配合评论置顶')"
          >
            <span>{{ getActionText(actions_2, form.supportPinComment) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          免费分发平台
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('hasFreeDistributionPlatform', actions_3, '免费分发平台')"
          >
            <span>{{ getActionText(actions_3, form.hasFreeDistributionPlatform) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          带话题
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('supportTopic', actions_2, '带话题')"
          >
            <span>{{ getActionText(actions_2, form.supportTopic) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          使用贴纸/BGM
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('supportUsePicOrBgm', actions_2, '使用贴纸/BGM')"
          >
            <span>{{ getActionText(actions_2, form.supportUsePicOrBgm) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          挂link费用
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('supportLink', actions_2, '挂link费用')"
          >
            <span>{{ getActionText(actions_2, form.supportLink) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
          <input
            v-if="form.supportLink"
            class="input second mini"
            placeholder="请输入价格"
            v-model="form.linkFee"
          >
          <span
            class="ext"
            v-if="form.supportLink"
          >
            元
          </span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          购物车费用
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('supportShoppingCart', actions_2, '购物车费用')"
          >
            <span>{{ getActionText(actions_2, form.supportShoppingCart) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
          <input
            v-if="form.supportShoppingCart"
            class="input second mini"
            placeholder="请输入价格"
            v-model="form.shoppingCartFee"
          >
          <span
            class="ext"
            v-if="form.supportShoppingCart"
          >
            元
          </span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          购物车佣金
        </div>
        <div class="right">
          <input
            class="input mini"
            placeholder="0-100"
            v-model="form.shoppingCartCommissionRate"
          ><span class="ext">%</span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          备注
        </div>
        <div class="right">
          <textarea
            class="textarea"
            v-model="form.remark"
          />
        </div>
      </div>
    </div>

    <div
      class="bottom-placeholder"
      v-if="hasPermission"
    />
    <div
      class="bottom"
      :style="'width:'+bottomWidth+'px;'"
      v-if="hasPermission"
    >
      <van-button
        class="btn-back"
        @click="back"
      >
        返回上一级
      </van-button>
      <van-button
        class="btn-save"
        :loading="saving"
        @click="save"
      >
        保存
      </van-button>
    </div>

    <van-action-sheet
      v-model:show="showSelectActions"
      :actions="actions"
      cancel-text="取消"
      :description="selectActionDesc"
      close-on-click-action
      @select="handleSelectAction"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SectionText from '@/components/SectionText';
import { Toast } from 'vant';

export default {
  name: 'EditBusinessInfo',
  components: { SectionText },
  data() {
    return {
      bottomWidth: 0,
      starId: this.$route.params.id,
      detail: undefined,
      hasPermission: true,
      showSelectActions: false,
      actions: [],
      selectActionType: '',
      selectActionDesc: '',
      actions_1: [
        { name: '授权', value: true },
        { name: '不授权', value: false },
        { name: '暂无', value: null },
      ],
      actions_2: [
        { name: '支持', value: true },
        { name: '不支持', value: false },
        { name: '暂无', value: null },
      ],
      actions_3: [
        { name: '有', value: true },
        { name: '无', value: false },
      ],
      form: {
        commissionRate: null,
        videoKeepDuration: null,
        authorizeVideo: null,
        authorizeVideoFee: null,
        supportSecondaryCreation: null,
        secondaryCreationFee: null,
        authorizedMarketingBrand: null,
        supportPinComment: null,
        hasFreeDistributionPlatform: null,
        supportTopic: null,
        supportUsePicOrBgm: null,
        linkFee: null,
        shoppingCartFee: null,
        shoppingCartCommissionRate: null,
        supportLink: null,
        supportShoppingCart: null,
        remark: null,
      },
      saving: false,
    };
  },
  computed: {
    ...mapState({
      configured: (state) => state.wecom.configured,
    })
  },
  created() {
    this.$nextTick(function () {
      const page_el = document.getElementById('edit-business-info-page');
      if (page_el) {
        this.bottomWidth = page_el.offsetWidth;
      }
    });
    this.toGetTiktokStarDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    async toGetTiktokStarDetail() {
      const res = await this.getTiktokStarDetail({ id: this.starId });
      if (!res) {
        return;
      }
      this.detail = res;
      this.form.commissionRate = res.commission_rate !== null ? (res.commission_rate / 100) : null;
      this.form.videoKeepDuration = res.video_keep_duration;
      this.form.authorizeVideo = res.authorize_video;
      this.form.authorizeVideoFee = res.authorize_video_fee !== null ? (res.authorize_video_fee / 100) : null;
      this.form.supportSecondaryCreation = res.support_secondary_creation;
      this.form.secondaryCreationFee = res.secondary_creation_fee !== null ? (res.secondary_creation_fee / 100) : null;
      this.form.authorizedMarketingBrand = res.authorized_marketing_brand;
      this.form.supportPinComment = res.support_pin_comment;
      this.form.hasFreeDistributionPlatform = res.has_free_distribution_platform;
      this.form.supportTopic = res.support_topic;
      this.form.supportUsePicOrBgm = res.support_use_pic_or_bgm;
      this.form.linkFee = res.link_fee !== null ? (res.link_fee / 100) : null;
      this.form.shoppingCartFee = res.shopping_cart_fee !== null ? (res.shopping_cart_fee / 100) : null;
      this.form.shoppingCartCommissionRate = res.shopping_cart_commission_rate !== null ? (res.shopping_cart_commission_rate / 100) : null;
      this.form.supportLink = res.support_link;
      this.form.supportShoppingCart = res.support_shopping_cart;
      this.form.remark = res.remark;
    },
    async save() {
      let commissionRate = null;
      if (this.form.commissionRate !== null && this.form.commissionRate !== '') {
        if (!/^\d+$/.test(this.form.commissionRate)) {
          Toast.fail('返点比例只支持0-100的整数');
          return;
        }
        commissionRate = parseInt(this.form.commissionRate);
        if (commissionRate < 0 || commissionRate > 100) {
          Toast.fail('返点比例只支持0-100的整数');
          return;
        }
      }

      if (this.form.videoKeepDuration && this.form.videoKeepDuration.length > 100) {
        Toast.fail('视频保留时长输入长度最大为100');
        return;
      }


      if (this.form.authorizeVideo && this.form.authorizeVideoFee !== null && !/^\d+$/.test(this.form.authorizeVideoFee)) {
        Toast.fail('视频授权费用只支持输入整数');
        return;
      }

      if (this.form.supportSecondaryCreation && this.form.secondaryCreationFee !== null && !/^\d+$/.test(this.form.secondaryCreationFee)) {
        Toast.fail('视频授权费用只支持输入整数');
        return;
      }

      if (this.form.authorizedMarketingBrand && this.form.authorizedMarketingBrand.length > 100) {
        Toast.fail('授权投放品牌DOU+输入长度最大为100');
        return;
      }

      if (this.form.supportLink && this.form.linkFee !== null && !/^\d+$/.test(this.form.linkFee)) {
        Toast.fail('挂link费用只支持输入整数');
        return;
      }

      if (this.form.supportShoppingCart && this.form.shoppingCartFee !== null && !/^\d+$/.test(this.form.shoppingCartFee)) {
        Toast.fail('购物车费用只支持输入整数');
        return;
      }

      let shoppingCartCommissionRate = null;
      if (this.form.shoppingCartCommissionRate!== null && this.form.shoppingCartCommissionRate !== '') {
        if (!/^\d+$/.test(this.form.shoppingCartCommissionRate)) {
          Toast.fail('购物车佣金只支持0-100的整数');
          return;
        }
        shoppingCartCommissionRate = parseInt(this.form.shoppingCartCommissionRate);
        if (shoppingCartCommissionRate < 0 || shoppingCartCommissionRate > 100) {
          Toast.fail('购物车佣金只支持0-100的整数');
          return;
        }
      }

      this.saving = true;
      const res = await this.updateTiktokStarBusinessInfo(
        {
          id: this.starId,
          commissionRate: commissionRate !== null ? commissionRate * 100 : null,
          videoKeepDuration: this.form.videoKeepDuration,
          authorizeVideo: this.form.authorizeVideo,
          authorizeVideoFee: this.form.authorizeVideo && this.form.authorizeVideoFee ? parseInt(this.form.authorizeVideoFee) * 100 : 0,
          supportSecondaryCreation: this.form.supportSecondaryCreation,
          secondaryCreationFee: this.form.supportSecondaryCreation && this.form.secondaryCreationFee ? parseInt(this.form.secondaryCreationFee) * 100 : 0,
          authorizedMarketingBrand: this.form.authorizedMarketingBrand,
          supportPinComment: this.form.supportPinComment,
          hasFreeDistributionPlatform: this.form.hasFreeDistributionPlatform,
          supportTopic: this.form.supportTopic,
          supportUsePicOrBgm: this.form.supportUsePicOrBgm,
          supportLink: this.form.supportLink,
          linkFee: this.form.linkFee ? parseInt(this.form.linkFee) * 100 : null,
          supportShoppingCart: this.form.supportShoppingCart,
          shoppingCartFee: this.form.shoppingCartFee ? parseInt(this.form.shoppingCartFee) * 100 : null,
          shoppingCartCommissionRate: shoppingCartCommissionRate  != null ? shoppingCartCommissionRate * 100 : null,
          remark: this.form.remark,
        }
      ).catch((e) => {
        this.saving = false;
      });
      this.saving = false;
      if (!res) {
        return;
      }

      Toast.success('保存成功');
      this.$router.back();
    },
    getActionText(actions, value) {
      let text = '-';
      actions.forEach((x) => {
        if (x.value === value) {
          text = x.name;
        }
      });
      return text;
    },
    showSelectAction(type, actions, desc) {
      this.selectActionType = type;
      this.actions = actions;
      this.selectActionDesc = desc;
      this.showSelectActions = true;
    },
    handleSelectAction(action, index) {
      this.form[this.selectActionType] = action.value;
    },
    ...mapActions({
      getTiktokStarDetail:
        'star/getTiktokStarDetail',
      updateTiktokStarBusinessInfo:
        'star/updateTiktokStarBusinessInfo',
    }),
  },
}
;
</script>

<style lang="less" scoped>
.edit-business-info-page {
  background: #fff;

  .star-info {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    box-shadow: 0 4px 10px 0 rgba(231, 231, 231, 0.5);

    .avatar {
      flex-shrink: 0;
      width: 108px;
      height: 108px;
      border-radius: 60px;
    }

    .common-info {
      width: 100%;
      margin-left: 30px;
      overflow: hidden;

      .nickname {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
      }

      .icon-gender {
        width: 26px;
        height: 26px;
        margin-left: 10px;
      }

      .brief {
        font-size: 24px;
        font-weight: 400;
        color: #999;
        line-height: 32px;
        margin-top: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .mcn {
        margin-left: 20px;

        .mcn-name {
          color: #0095FF;
        }
      }
    }
  }

  .edit-business-form {
    padding: 0 40px 40px;

    .field {
      display: flex;
      align-items: center;

      font-size: 28px;
      font-weight: 400;
      color: #333;
      line-height: 34px;

      &:nth-child(n+2) {
        margin-top: 30px;
      }

      .title {
        flex-shrink: 0;
        width: 220px;
        font-weight: 600;
      }

      .right {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .input {
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        border-radius: 40px;
        border: 1px solid #EBEBEB;
        padding: 0 30px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
      }

      .textarea {
        width: 100%;
        height: 200px;
        background: #FFFFFF;
        border-radius: 40px;
        border: 1px solid #EBEBEB;
        padding: 30px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        resize: none;
      }

      .select {
        display: flex;
        position: relative;
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        border-radius: 40px;
        border: 1px solid #EBEBEB;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-right: 30px;

        .arrow {
          width: 20px;
          height: 10px;
          position: absolute;
          right: 20px;
        }
      }

      .mini {
        width: 50%;
        text-align: center;
      }

      .second {
        margin-left: 10px;
      }

      .ext {
        font-size: 28px;
        font-weight: 400;
        color: #333;
        line-height: 34px;
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }

  .bottom-placeholder {
    height: 160px;
  }

  .bottom {
    display: flex;
    align-items: center;
    background: #fff;
    height: 160px;
    border-top: 1px solid #E6E6E6;
    position: fixed;
    bottom: 0;

    .btn-back {
      width: 40%;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #0095FF;
      font-size: 30px;
      font-weight: 600;
      color: #0095FF;
      line-height: 30px;
      margin-left: 30px;

      &:active {
        opacity: 0.8;
      }
    }

    .btn-save {
      width: 60%;
      background: #0095FF;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 30px;

      &:active {
        opacity: 0.8;
      }
    }
  }
}
</style>